<template>
    <modal ref="modalAnadirCompra" titulo="Añadir Compra" :cargado-modal="cargando" :tamano="'modal-lg'" @guardar="agregar_stock">
        <ValidationObserver ref="validator">
            <div class="row mx-0">
                <div class="col-1" />
                <div class="col-10">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <img :src="producto.imagen" width="89" height="89" class="obj-cover br-5" />
                        </div>
                        <div class="col">
                            <p class="text-muted2 my-1 rompe-palabras">
                                {{ producto.nombre }}
                            </p>
                            <p class="text-muted f-14 my-1">
                                {{ producto.presentacion }} {{ producto.unidad }}
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 mt-3 mb-3">
                        <div class="col-4">
                            <ValidationProvider v-slot="{errors}" :rules="sellRules" name="cantidad">
                                <label>Cantidad</label>
                                <el-input v-model="model.cantidad" size="small" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-8">
                            <ValidationProvider v-slot="{errors}" rules="required|max_value:9999999.999" name="valor">
                                <label class="pl-3 f-14"> Valor Costo </label>
                                <div class="d-middle">
                                    <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                                        {{ sigla_actual }}
                                    </div>
                                    <input-miles-moneda v-model="model.valor" :id-moneda="moneda_cedis" maxlength="13" />
                                </div>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-6 mt-3">
                            <label class="pl-3 text-muted"> Fecha de vencimiento </label>
                            <el-date-picker
                            v-model="model.vencimiento"
                            type="date"
                            placeholder="Seleccionar"
                            :picker-options="pickerOptions"
                            size="small"
                            class="w-100"
                            format="yyyy/MM/dd"
                            value-format="yyyy-MM-dd"
                            />
                        </div>
                        <div class="col-6 mt-3">
                            <label class="pl-3 text-muted"> Proveedor </label>
                            <el-select v-model="model.id_proveedor" filterable size="small" clearable class="w-100">
                                <el-option
                                v-for="item in proveedores"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <div class="col-12 mt-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="observación">
                                <label class="pl-3 f-14"> Observación de la compra </label>
                                <el-input v-model="model.comentario" type="textarea" class="w-100" :rows="4" />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="row mx-0 py-0 mt-3">
                            <div class="col-6">
                                <p class="row text-general f-600 mx-0">
                                    Último Costo reportado
                                </p>
                                <div class="row mx-0 py-2">
                                    <div class="col-6">
                                        <img :src="_.get(producto.ultimo_valor_costo,'creador.foto','')" width="89" height="89" class="obj-cover br-4" />
                                    </div>
                                    <div class="col">
                                        <p class="text-muted2 my-1 rompe-palabras">
                                            {{ _.get(producto.ultimo_valor_costo,'creador.nombre','') }}
                                        </p>
                                        <p class="text-muted f-14 my-1">
                                            {{ formatearFecha(_.get(producto.ultimo_valor_costo,'created_at','')) }}
                                        </p>
                                        <p class="text-muted f-14 my-1">
                                            {{ separadorNumero(_.get(producto.ultimo_valor_costo,'valor',0)) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <p class="row text-general f-600 mx-0">
                                    Último valor de Venta
                                </p>
                                <div class="row mx-0 py-2">
                                    <div class="col-auto">
                                        <img :src="_.get(producto.ultimo_valor_venta,'creador.foto','')" width="89" height="89" class="obj-cover br-4" />
                                    </div>
                                    <div class="col">
                                        <p class="text-muted2 my-1 rompe-palabras">
                                            {{ _.get(producto.ultimo_valor_venta,'creador.nombre','') }}
                                        </p>
                                        <p class="text-muted f-14 my-1">
                                            {{ formatearFecha(_.get(producto.ultimo_valor_venta,'created_at','')) }}
                                        </p>
                                        <p class="text-muted f-14 my-1">
                                            {{ separadorNumero(_.get(producto.ultimo_valor_venta,'valor',0)) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-1" />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import CedisProductos from '~/services/cedis/cedis_productos'
import ProductosStocks from '~/services/productos/productos_stocks'
import moment from 'moment'
export default {
    data(){
        return{
            cargando: false,
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            sigla_actual: '',
            proveedores: [],
            moneda_cedis: 0,
            producto: {
                imagen: null,
                presentacion: null,
                unidad: null,
                ultimo_valor_costo: {
                    valor: '',
                    created_at: '',
                    creador: {
                        nombre: '',
                        foto: '',
                    },
                },
                ultimo_valor_venta: {
                    valor: '',
                    created_at: '',
                    id_proveedor: '',
                    creador: {
                        nombre: '',
                        foto: '',
                    },
                },
                cantidad_minima: 0,
                cantidad_tipo: 0,
            },
            model:{
                valor: null,
                cantidad: null,
                id_proveedor: null,
                vencimiento: null,
            },
            pickerOptions: {
                disabledDate:this.disabledDate,
            },
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            id_moneda:'cedis/id_moneda',
            monedas:'cedis/monedas',
            id_cedis:'cedis/id_cedis',
            id_cedis_navbar:'cedis/id_cedis_navbar',
            cedis:'cedis/cedis'

        }),
        sellRules(){
            if (this.producto.cantidad_tipo == 2){
                return `required|min_value:${this.producto.cantidad_minima}|max_value:99999`
            }
            return 'required|min_value:1|max_value:99999'
        }
    },
    methods: {
        toggle(){
            this.limpiarForm()
            this.listaProveedores()
            this.info_producto_stock()
            const id_cedis = this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis;
            this.moneda_cedis = this.calcularMonedaCedis(id_cedis)
            this.sigla_actual = this.calcularSiglaActual(id_cedis)
            this.$refs.modalAnadirCompra.toggle();
        },
        async agregar_stock(){
            try {
                const valid = await this.$refs.validator.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'Campos obligaorios', 'warning')
                    return false
                }
                this.model.id_producto = this.id_producto
                this.model.id_cedis= this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis
                this.cargando = true
                const {data} = await  ProductosStocks.agregarStock(this.model)
                this.cargando = false
                this.notificacion('Mensaje', 'Compra registrada', 'success')
                this.$emit('actualizar')
                this.$refs.modalAnadirCompra.toggle();

            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        async info_producto_stock(){
            try {
                const params ={
                    id_cedis: this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis
                }
                const {data} = await  ProductosStocks.info_producto_stock(this.id_producto, params)
                this.cargando = false
                this.producto = data.producto
                this.model.id_proveedor = this.producto.ultimo_valor_venta.id_proveedor


            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        async listaProveedores(){
            try {
                const id_cedis = this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis_navbar;

                const {data} = await CedisProductos.listaProveedores({id_cedis})
                this.proveedores = data.proveedores
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarForm(){
            this.producto= {
                vencimiento: null,
                imagen: null,
                presentacion: null,
                unidad: null,
                ultimo_valor_costo: {
                    valor: '',
                    created_at: '',
                    creador: {
                        nombre: '',
                        foto: '',
                    },
                },
                ultimo_valor_venta: {
                    valor: '',
                    created_at: '',
                    id_proveedor: '',
                    creador: {
                        nombre: '',
                        foto: '',
                    },
                },
                cantidad_minima: 0,
                cantidad_tipo: 0,
            }
            this.model={
                valor: null,
                cantidad: null,
                id_proveedor: null,
            }
            this.$refs.validator.reset()
        },
        disabledDate(time){
            return time.getTime() < Date.now();
        }
    }
}
</script>

<style>

</style>
